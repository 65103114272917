/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)


import './application-bundle'
import * as bootstrap from 'bootstrap';
import html2canvas from 'html2canvas';
import Rails from '@rails/ujs';  // Import the rails-ujs library
Rails.start();  // Initialize rails-ujs

$(document).ready(function() {
    $(".dropdown-toggle").dropdown();
});
window.html2canvas = html2canvas;
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});


document.addEventListener('turbo:load', () => {
    console.log('Turbo has loaded the page!');
});
document.addEventListener('turbo:before-stream-render', () => {
    console.log('Before stream render!');
  });
  
  document.addEventListener('turbo:stream-render', () => {
    console.log('Stream rendered!');
  });
document.addEventListener('turbo:render', () => {
    console.log('Turbo has rendered the page!');
   
  });
Turbo.session.drive = true;


window.onerror = function(message, source, lineno, colno, error) {
  // Prepare error data
  var errorData = {
      message: message,
      source: 'web',
      backtrace: error ? error.stack : null
  };

  // Send error data to backend
  $.ajax({
      url: '/errors',
      type: 'POST',
      data: JSON.stringify(errorData),
      contentType: 'application/json',
      success: function(response) {
          console.log('Error sent to backend successfully.');
      },
      error: function(xhr, status, error) {
          console.error('Error sending error to backend:', error);
      }
  });
};
// TODO: find a better way to do this. Since color palette  is loaded dynamically as html in chat session importing using content_for doesn't work.
window.copyHexCode = function copyHexCode(hex, element) {
  navigator.clipboard.writeText(hex).then(
    () => {
      // Show a temporary "Copied!" message
      const successMessage = document.createElement('span');
      successMessage.innerText = 'Copied!';
      successMessage.style.color = 'green';
      successMessage.style.fontSize = '0.75em';
      successMessage.style.marginLeft = '10px';

      element.appendChild(successMessage);

      // Remove the message after a short delay
      setTimeout(() => successMessage.remove(), 2000);
    },
    (err) => console.error("Failed to copy text: ", err)
  );
}

document.addEventListener("DOMContentLoaded", () => {
  // Function to add copy button to a code block
  function addCopyButton(block) {
    // Check if a copy button is already added to avoid duplicates
    if (block.querySelector("button")) return;

    // Create the copy button
    const copyButton = document.createElement("button");
    copyButton.innerText = "Copy";
    copyButton.className = "tw-absolute tw-top-2 tw-right-2 tw-bg-gray-500 tw-text-white tw-py-1 tw-px-2 tw-rounded tw-text-xs hover:tw-bg-gray-700 tw-transition tw-duration-200";

    // Add the copy functionality
    copyButton.addEventListener("click", async () => {
      // Get the inner text of the <code> element inside the <pre>
      let code = block.querySelector("code")?.innerText;
      // the hex is replaced with span to display color. Because of that it adds new line. Remove it before coping.
      code = code.replace(/\n(?=#([0-9A-Fa-f]{6}))/g, "");
      if (!code) return;

      try {
        await navigator.clipboard.writeText(code);
        copyButton.innerText = "Copied!";
        setTimeout(() => {
          copyButton.innerText = "Copy";
        }, 1500);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    });
    block.classList.add("tw-relative", "tw-p-4", "tw-bg-gray-800", "tw-rounded");
    block.appendChild(copyButton);
  }
  const codeBlocks = document.querySelectorAll("pre");
  codeBlocks.forEach(addCopyButton);

  // Use MutationObserver to add copy button to dynamically added code blocks
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeName === "PRE") {
          addCopyButton(node);
        } else if (node.querySelectorAll) {
          node.querySelectorAll("pre").forEach(addCopyButton);
        }
      });
    });
  });
  observer.observe(document.body, { childList: true, subtree: true });
});
window.shareColorPalette = function sharePalette(event, url) {
  event.preventDefault();

  // Check if the browser supports the Web Share API
  if (navigator.share) {
      navigator.share({
          title: 'Check out this color palette!',
          text: 'Here’s a color palette you might like:',
          url: url,
      })
      .then(() => console.log('Successfully shared'))
      .catch((error) => console.error('Something went wrong sharing:', error));
  } else {
      // Fallback: Copy the URL to the clipboard
      navigator.clipboard.writeText(url)
          .then(() => alert('Link copied to clipboard!'))
          .catch(() => alert('Failed to copy link to clipboard.'));
  }
}

window.saveSessionData = async function saveSessionData(key, value) {
  try {
    const response = await fetch('/save_session_data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ key: key, value: value }),
    });

    if (!response.ok) {
      console.error(`Failed to save session data: ${response.statusText}`);
      return false;
    }

    const result = await response.json();
    if (result.status === 'success') {
      return true;
    } else {
      console.error(`Server error: ${result.message}`);
      return false;
    }
  } catch (error) {
    console.error("Error saving session data:", error);
    return false;
  }
}